import { useState, useEffect } from "react";
import * as React from 'react';
import styles from "./paymentForm.module.css";
import { useImplicitAuth } from "../../azureLogin/useImplicitAuth";
import OrderView from "../Payments/OrderView";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import valid from 'card-validator';
import Loader from '../loader';
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const PaymentForm = ({ order }) => {
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState(styles.messageok);
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState(null);
  const [isCardValid, setIsCardValid] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAmountZero, setIsAmountZero] = useState(false);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isRefundPolicyChecked, setIsRefundPolicyChecked] = useState(false);
  const [declineErrorMsg, setDeclineErrorMsg] = useState('');
  const [errorMessageClass,setErrorMessageClass] = useState('');


  const { fetchData, jwt } = useImplicitAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // This will trigger a re-render when the message state changes
    console.log("Message updated:", message);
  }, [message]);

  useEffect(() => {
    console.log("decline error message", declineErrorMsg);
  }, [declineErrorMsg]);

  const resetForm = () => {

    setCardNumber('');
    setCardType(null);
    setIsCardValid(false);
    setExpiryDate('');
    setCvv('');
    setCity('');
    setState('');
    setPostalCode('');
    setCountry('');
    setFirstName('');
    setLastName('');
    
    setErrors({});

    setMessage('');
    setMessageClass(styles.messageok);
    setIsRefundPolicyChecked(false);
    document.getElementById('form').reset();
  };

  useEffect(() => {
    if (order.lineBalance === 0) {
      setIsAmountZero(true);
      setMessage("No Due Amount");
      setMessageClass(styles.messageok);
    }
  }, [order.lineBalance]);
 
  const showResponseModal = (responseDetails) => {
    const { success, name, orderId, amountPaid, message, transactionId, remainingBalance, orderDate } = responseDetails;

    MySwal.fire({
      title: success ? "Payment Successful" : "Payment Declined",
      html: `
      <div class='text-left payment-block'>
        <h5>Payment Details:</h5>
        <p><strong>Name:</strong> ${name}</p>
        <p><strong>Order ID:</strong> ${orderId}</p>
        <p><strong>Order Date: </strong>${orderDate} </p>
        <p><strong>Transaction ID:</strong> ${transactionId} </p>
        <p><strong>Amount ${success ? "Paid" : "Attempted"}:</strong> $${amountPaid}</p>
        <p><strong>Remaining Balance Due:</strong> ${remainingBalance}</p>
        <p><strong>Status:</strong> ${message}</p>
      </div>
      `,
      icon: success ? "success" : "error",
      confirmButtonText: "Close",
      confirmButtonColor: success ? "#28a745" : "#dc3545",
      allowOutsideClick: false
    }).then((result) => {
      if (success && result.isConfirmed) {
        navigate("/");
      }
    });
  };

  const showDeclineConfirmModal = () => {
    MySwal.fire({
      title: "Refuse Payment",
      text: "Are you sure you want to Refuse the payment? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, Refuse Payment",
      cancelButtonText: "No",
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://integration-api-dev.cgfns.org/paypal/declinePaymentRequest/${order.orderNumber}?payerEmail=${order.ThirdPartyPayerEmail}`, {
            method: "GET",
          });
  
          const responseData = await response.json();
  
          console.log("decline response", response);
          if (!response.ok) {
            console.log("failed");
            // Handle API error
            const errormsg = responseData.error?.message;
            console.log(errormsg);
            setDeclineErrorMsg(`Failed to refuse payment: ${errormsg || "Unknown error"}`);
        

            setErrorMessageClass(styles.messageerror);
          } else {
            // Successfully declined payment

            setDeclineErrorMsg("Payment declined by user.");
            // displayErrorMesssage();

            setErrorMessageClass(styles.messageerror);

          }
        } catch (error) {
          // Handle unexpected errors
          setDeclineErrorMsg(`An error occurred while refusing the payment: ${error.message}`);
          setErrorMessageClass(styles.messageerror);
        }
  
        resetForm();
      }
    });
  };

  const handleCardNumberChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setCardNumber(input);

    const numberValidation = valid.number(input);
    if (numberValidation.card) {
      setCardType(numberValidation.card.type);
      setIsCardValid(numberValidation.isValid);
    } else {
      setCardType(null);
      setIsCardValid(false);
    }

    setErrors(prev => ({ ...prev, cardNumber: input.length !== 16 ? 'Card number must be 16 digits' : '' }));
  };

  const handleExpiryDateChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    let formattedInput = input;
    if (input.length > 4) {
      input = input.slice(0, 6);
      formattedInput = input.slice(0, 4) + '/' + input.slice(4);
    } else if (input.length === 4) {
      formattedInput = input + '/';
    }
    setExpiryDate(formattedInput);
    let error = '';
    if (input.length === 6) {  
      const year = parseInt(input.slice(0, 4));
      const month = parseInt(input.slice(4, 6));
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (year < currentYear || (year === currentYear && month < currentMonth)) {
        error = 'Expiry date must be in the future';
      } else if (month > 12 || month === 0) {
        error = 'Invalid month';
      }
    }
    setErrors(prev => ({ ...prev, expiryDate: error }));
  };

  const handleCvvChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setCvv(input);
    setErrors(prev => ({ ...prev, cvv: input.length !== 3 ? 'CVV must be 3 digits' : '' }));
  };

  const formatCardNumber = (number) => {
    const groups = number.match(/\d{1,4}/g) || [];
    return groups.join(' ');
  };

  const handleCityChange = (e) => {
    const input = e.target.value;
    setCity(input);
    setErrors(prev => ({
      ...prev,
      city: input.trim() === '' || !/^[a-zA-Z\s]*$/.test(input) ? 'City must contain only letters.' : ''
    }));
  };
  
  const handleStateChange = (e) => {
    const input = e.target.value;
    setState(input);
    setErrors(prev => ({
      ...prev,
      state: input.trim() === '' || !/^[a-zA-Z\s]*$/.test(input) ? 'State must contain only letters.' : ''
    }));
  };
  
  const handlePostalCodeChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    setPostalCode(input);
    // setErrors(prev => ({ ...prev, cvv: input.length !== 3 ? 'CVV must be 3 digits' : '' }));
  };
  
  const handleCountryChange = (e) => {
    const input = e.target.value;
    setCountry(input);
    setErrors(prev => ({
      ...prev,
      country: input.trim() === '' || !/^[a-zA-Z\s]*$/.test(input) ? 'Country must contain only letters.' : ''
    }));
  };
  const handleFirstNameChange = (e) => {
    const input = e.target.value;
    setFirstName(input);
    setErrors(prev => ({
      ...prev,
      firstName: input.trim() === '' || !/^[a-zA-Z\s]*$/.test(input) ? 'First Name must contain only letters.' : ''
    }));
  };
  
  const handleLastNameChange = (e) => {
    const input = e.target.value;
    setLastName(input);
    setErrors(prev => ({
      ...prev,
      lastName: input.trim() === '' || !/^[a-zA-Z\s]*$/.test(input) ? 'Last Name must contain only letters.' : ''
    }));
  };
  


  const submitHandler = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!isRefundPolicyChecked) {
      setMessage("Please agree to the refund policy to proceed.");
      setMessageClass(styles.messageerror);
      return;
    }

   if (!isCardValid || errors.expiryDate || errors.cvv) {
      setMessage("Please correct the errors in the form.");
      setMessageClass(styles.messageerror);
      return;
    }
  
    setIsLoading(true);
  
    try {
      const form = e.target;
      const formData = new FormData(form);
      const formObj = Object.fromEntries(formData.entries());
      
      // Format credit card number and expiration date
      formObj.creditCardNbr = formObj.creditCardNbr.replace(/\s/g, '');
      formObj.expirationDate = `${formObj.expirationDate.slice(0,4)}-${formObj.expirationDate.slice(5)}-01`;
      formObj.customersId = order.customersId;
      formObj.orderHeadersId = order.orderHeadersId;
      formObj.trxAmt = order.lineBalance;
      
      // API call
      const response = await fetchData(`/payflow/charge/${order.orderNumber}`, {
        method: "POST",
        body: JSON.stringify(formObj)
      });
  
      const responseData = await response.json();
  
      // Handle response
      if (!response.ok) {
        console.error('Payment Error:', responseData);
        const errorMessage = responseData.error?.message || 'An error occurred during payment processing';
        setMessage(`${errorMessage} (error code: ${responseData.error?.statusCode || 'unknown'})`);
        setMessageClass(styles.messageerror);
  
        // Show the modal even for errors to inform the user
        showResponseModal({
          success: false,
          name: `${formObj.firstName} ${formObj.lastName}`,
          orderId: order.orderNumber,
          amountPaid: order.lineBalance,
          message: errorMessage,
          transactionId: responseData.pnref || 'N/A',
          remainingBalance: responseData.bal || 'N/A',
          orderDate: new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
        });
  
      } else {
        console.log('Payment Response:', responseData);
        const { message, result, pnref, bal } = responseData;
  
        setMessage(message);
        setMessageClass(result === 0 ? styles.messageok : styles.messageerror);
  
        // Show the modal for successful payment
        showResponseModal({
          success: result === 0,
          name: `${formObj.firstName} ${formObj.lastName}`,
          orderId: order.orderNumber,
          amountPaid: order.lineBalance,
          message,
          transactionId: pnref,
          remainingBalance: bal,
          orderDate: new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
          })
        });
      }
    } catch (error) {
      console.error('Payment Processing Error:', error);
      setMessage(error.message || 'An error occurred while processing your payment');
      setMessageClass(styles.messageerror);
  
      // Show a modal for unexpected errors
      showResponseModal({
        success: false,
        name: 'N/A',
        orderId: 'N/A',
        amountPaid: 'N/A',
        message: error.message || 'An unexpected error occurred.',
        transactionId: 'N/A',
        remainingBalance: 'N/A',
        orderDate: new Date().toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const SubmitPayment = () => (
    <>
      <button type="submit" className={styles.button + " btn btn-primary"}  disabled={isLoading || isAmountZero}>
        {isLoading ? 'Processing...' : 'Pay'}
      </button>

      <button type="button" onClick={showDeclineConfirmModal} className={styles.button + " btn btn-danger"}>
      Refuse Payment
      </button>
    </>
  );

  const Message = ({ content, className }) => <p className={className}>{content}</p>;

  return (
    <div className={`${styles.form} payment-section`}>
      {isAmountZero ? (
        <p className={messageClass}>{message}</p>
      ) : (
        <>
          <OrderView order={order} />
          <hr className={styles.divider} />

          <h3 className={styles.heading}>Card Details</h3>
          <form id="form" className="form" onSubmit={submitHandler}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    name="creditCardNbr"
                    type="text"
                    placeholder="Card Number"
                    className={`${styles.input} form-contorl`}
                    value={formatCardNumber(cardNumber)}
                    onChange={handleCardNumberChange}
                    maxLength="19"
                  />
                  {errors.cardNumber && <p className="small text-danger">{errors.cardNumber}</p>}
                  {cardType && (
                    <p className="small text-dark">
                      Card Type: {cardType.charAt(0).toUpperCase() + cardType.slice(1)}
                    </p>
                  )}
                  {!cardType && cardNumber.length > 0 && (
                    <p className="small text-danger">Invalid card number</p>
                  )}
                </div>
              </div>
            </div>

            
            <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                name="expirationDate"
                type="text"
                placeholder="Expiration Date: YYYY/MM"
                className={`${styles.input} form-control`}
                value={expiryDate}
                onChange={handleExpiryDateChange}
                maxLength="7"
              />
              {errors.expiryDate && <div className="small text-danger">{errors.expiryDate}</div>}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <input
                name="securityCode"
                maxLength={3}
                type="text"
                placeholder="CVV"
                className={styles.input}
                value={cvv}
                onChange={handleCvvChange}
              />
              {errors.cvv && <p className="small text-danger">{errors.cvv}</p>}
            </div>
          </div>


        </div>
       

        <div className="row">
  <div className="col-md-6">
    <div className="form-group">
      <input
        name="firstName"
        type="text"
        placeholder="First Name on Card"
        className={`${styles.input} form-control`}
        value={firstName}
        onChange={handleFirstNameChange}
      />
      {errors.firstName && <p className="small text-danger">{errors.firstName}</p>}
    </div>
  </div>

  <div className="col-md-6">
    <div className="form-group">
      <input
        name="lastName"
        type="text"
        placeholder="Last Name on Card"
        className={`${styles.input} form-control`}
        value={lastName}
        onChange={handleLastNameChange}
      />
      {errors.lastName && <p className="small text-danger">{errors.lastName}</p>}
    </div>
  </div>
</div>

         
         <div className="row">
          <div className="col-md-12">
            <div className="form-group">
            <input name="streetAddress1" 
            type="text" 
            placeholder="Street Address" 
            className={styles.input}
            />
            </div>
          </div>
         </div>

       
        <div className="row">
          <div className="col-md-6">
           <input 
            name="city" 
            type="text" 
            placeholder="City" 
            className={styles.input} 
            value={city}
            onChange={handleCityChange}
          />
           {errors.city && <p className="small text-danger">{errors.city}</p>}
          </div>
          <div className="col-md-6">  
            <input 
              name="stateProvince" 
              type="text" 
              placeholder="State" 
              className={styles.input}
              value={state}
              onChange={handleStateChange}
            />
             {errors.state && <p className="small text-danger">{errors.state}</p>}
          </div>
         
         
        </div>

        <div className="row">
        <div className="col-md-6">
          <input 
            name="postalCode" 
            type="text" 
            placeholder="Postal Code" 
            className={styles.input} 
            value={postalCode}
            onChange={handlePostalCodeChange}
          />
        </div>

        <div className="col-md-6">
          <input 
            name="country" 
            type="text" 
            placeholder="Country" 
            className={styles.input}
            value={country}
            onChange={handleCountryChange} 
          />
           {errors.country && <p className="small text-danger">{errors.country}</p>}
        </div>

        <div className="form-group refund-policy">
        <input
          type="checkbox"
          id="refundPolicy"
          checked={isRefundPolicyChecked}
          onChange={() => setIsRefundPolicyChecked(!isRefundPolicyChecked)}
          className="custom-checkbox"
          style={{ width: '16px', height: '16px' }} // Adjust as needed
        />
        <label htmlFor="refundPolicy" className="small text-dark" style={{ color: 'red !important' }}>
          <span className="agree-text">I Agree</span> - By checking this checkbox, I agree and understand that CGFNS International has a NO REFUND policy. No refund will be given after the payment is made.
        </label>
        </div>

         
        </div>
        <div style={{ marginTop: '30px' }} className="button-group">
  <SubmitPayment />
</div>
          </form>
        
          {message && <Message content={message} className={messageClass} />}
          {declineErrorMsg && <Message content={declineErrorMsg} className={errorMessageClass} />}
          {isLoading && <Loader visible={true} />}
        </>
      )}
    </div>
  );
};
