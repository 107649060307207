import React from "react";
import '../../../assets/css/icd.css';
import Header from '../../../components/icd/icdHeader/icdHeader';
import Footer from '../../../components/icd/icdFooter/icdFooter';
import OrderHistory from '../../../components/cOrderHistory';

const IcdOrderHistory = () => {
    return (
<>
<Header></Header>
<div className="container-custom-color-history-ICD">
<OrderHistory isICD={true}></OrderHistory>
</div>
<Footer></Footer>
</>
 );
}
export default IcdOrderHistory;