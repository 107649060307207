import React,{useState,useEffect} from 'react';
import '../../../assets/css/cgfns.css';
import Header from '../../../components/cgfns/cgfnsHeader/cgfnsHeader';
import Footer from '../../../components/cgfns/cgfnsFooter/cgfnsFooter';
import { useTranslation } from 'react-i18next';
import OrderHistory from '../../../components/cOrderHistory';
import { AuthenticatedTemplate } from '@azure/msal-react';

const CgfnsOrderHistory = () => {
  
  return (
    <>
    <Header></Header>
    <AuthenticatedTemplate>
    <div className="container-custom-color-history">
    <OrderHistory isICD={false}></OrderHistory>
    </div>
    <Footer></Footer>
    </AuthenticatedTemplate>
    </>
     );
}
export default CgfnsOrderHistory;