import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/icd.css';
import Header from '../../../components/icd/icdHeader/icdHeader';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Footer from '../../../components/icd/icdFooter/icdFooter';
import { useTranslation } from 'react-i18next';
import CHomePage from "../../../components/cHomePage"

const HomePage= () => {
  
  const { t, i18n } = useTranslation();
  return (

    <>
    <Header></Header>
    <div className="container-custom-color">
    <CHomePage isICD={true}></CHomePage>    
    </div>
    <Footer></Footer>
    </>
     );
}
export default HomePage;