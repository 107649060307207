import React from 'react';

const Loader = ({ visible = true }) => {
  if (!visible) return null;

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

  const messageStyle = {
    marginBottom: '20px',
    fontSize: '16px',
    color: '#333',
    position:'absolute',
    top:'25%'
  };

  const warningStyle = {
    marginTop: '20px',
    padding: '8px 16px',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    fontSize: '14px',
    color: '#333'
  };

  const loaderContainer = {
    position: 'relative',
    height: '80px'
  };
    // width: '80px',

  return (
    <div style={overlayStyle}>
      <div style={messageStyle}>Please wait, we are processing your order.</div>
      <div style={loaderContainer}>
        {[...Array(16)].map((_, index) => {
          const rotation = (index * 360) / 16;
          const delay = index * 0.125;
          const dotStyle = {
            position: 'absolute',
            width: '8px',
            height: '8px',
            backgroundColor: index === 15 ? '#000' : '#ccc',
            borderRadius: '50%',
            transform: `rotate(${rotation}deg) translate(30px)`,
            transformOrigin: '0 50%',
            animation: `rotateLoader 2s infinite ${delay}s`
          };
          
          return <div key={index} style={dotStyle} />;
        })}
      </div>
      <div style={warningStyle}>
        Please do not press back or refresh your browser.
      </div>
      <style>
        {`
          @keyframes rotateLoader {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0.2;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Loader;
