import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmPopupAddtRecpt = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} aria-labelledby="modal-heading-01" aria-modal="true">
      <Modal.Header closeButton>
        <Modal.Title id="modal-heading-01">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><b>Do you want to proceed to purchase an Additional Recipient?</b></p>
        <p className="text-muted">
          Select 'Yes' only if you want to purchase the service.
        </p>
        <p><b>Note:</b> "By selecting <b>YES</b>, you will have an open order in your shopping cart and your <b>Account Balance</b> will increase until the order has been paid."</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopupAddtRecpt;
