import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './i18n';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig, b2cPolicies, protectedResources } from './azureLogin/msalConfig';
import { CustomNavigationClient } from './azureLogin/navigateClient';
import { implicitAuthRedirect } from "./azureLogin/useImplicitAuth";

// Create PublicClientApplication instance
const pca = new PublicClientApplication(msalConfig);

function MsalWrapper({ children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <MsalWrapper>
        <App />
      </MsalWrapper>
    </BrowserRouter>
  );
}

// Initialize MSAL and render the application
const initializeMsal = async () => {
  try {
    await pca.initialize().then(() => {

      pca.addEventCallback( (event ) =>  {

        if (event.eventType === EventType.LOGIN_FAILURE) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (event && event.error && event.error.errorMessage && event.error.errorMessage.includes("AADB2C90118")) {
            const resetPasswordRequest = {
              authority: b2cPolicies.authorities.forgotPassword.authority,
              scopes: [
                ... protectedResources.apiContact.scopes.me
              ],
            };
            pca.loginRedirect(resetPasswordRequest);
          }
        }
      });
    });

    await pca.handleRedirectPromise();

    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
      <React.StrictMode>
        <AppWrapper />
      </React.StrictMode>
    );
  } catch (error) {
    console.log(error);
  }
};

//if this page is in the Payments site, check if there is a TOTP in the URL and 
//redirect to the Entra B2C IdP to exchange this for an access token. 
//Doing this redirect very early in the page lifecycle avoids screen redraws and 
//most users will not notice that they were briefly detoured to the B2C authorize endpoint.
if (window.location.pathname.startsWith("/payments")) {
  implicitAuthRedirect();
}

initializeMsal();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
