import { io, Socket } from "socket.io-client"



export class SocketIoManager {

    socket = undefined;
    next_id = 0;
    token = undefined;
    subscriptions = new Map();
    namespace = undefined;
    state = "Disconnected";

    static managers = new Map();

    static getSocketIoManager(namespace) {

        let mgr = SocketIoManager.managers.get(namespace);
        if (!mgr) {
            mgr = new SocketIoManager(namespace);
            SocketIoManager.managers.set(namespace,mgr);
        }
        return mgr;
    }

    constructor(namespace) {
        console.log("New SocketIoManager: " + namespace);
        this.namespace = namespace;
    }

    async connect(token)  {

        this.token = token;

        return new Promise((resolve,reject) => {

        if (!this.socket || (this.state == "Disconnected")) {
        
                this.state = "Connecting";
                this.socket = io(`${process.env.REACT_APP_WEBSITE_NAMES}${this.namespace}`, {
                    extraHeaders: { Authorization: "Bearer " + token }}); 
        
                this.socket.on( "connect", () => {
                    this.state = "Connected";
                    console.log("Connection opened " + (this.socket?.recovered ? "- Previous session recovered" : ""));

                    const channels = new Set();
                    this.subscriptions.keys().forEach( k => {
                        const [ev, ch] = k.split('/');
                        channels.add(ch);
                    })
                    let aChannels = Array.from( channels.values());
                    this.socket.emit("subscribe",aChannels);
                    resolve(this.socket);
                });

                this.socket.on( "subscribed", (msg) => {
                    console.log("Subscribed",msg)
                });

                this.socket.on( "unsubscribed", (msg) => {
                    console.log("unsubscribed",msg)
                });

                this.socket.on( "disconnect", () => {
                    //this.socket = undefined;
                    console.log("Connection closed")
                });

                this.socket.onAny(  (event,data) => {
                console.log(`Socketio.event: ${event} = ${data}`);
                    
                
                    let subs = this.subscriptions.entries().filter( ([k,v]) => k.startsWith(event + '/'));
                    console.log("Dispatch Event",event,subs,data);
                    subs.forEach( ([channel,callbacks]) => {
                        callbacks.forEach( cb => cb(event,channel,data));
                    })

                });
       
             }
    
         });
}



    async subscribe( channels, event,  cb)  {

    if (this.socket) {

        if (!Array.isArray(channels)) {
            channels = [channels];
        }

        let events =  new Set();

        channels.forEach( c => {
            let n = `${event}/${c}`;
            let o = this.subscriptions.get(n);
            if (o) {
                if (!o.includes(cb))
                    o.push(cb);
            } 
            else {
                this.subscriptions.set(n, [cb]);
                events.add(c);
            }

         
        });
        let aEvents = Array.from( events.values());
        console.log("Subscribing", aEvents)
        this.socket.emit("subscribe",aEvents)
    }

}

    unsubscribe ( channels, event,  cb)  {

    if (this.socket) {

        if (!Array.isArray(channels)) {
            channels = [channels];
        }

        const unsubs = new Set();
        channels.forEach( c => {
            let n = `${event}/${c}`;
            let s = this.subscriptions.get(n);
            if (s) {
                if (cb) {
                    let i = s.indexOf(cb);
                    if (i >= 0) {
                        s.splice(i,1);
                    }
                    if (!s.length) {
                        this.subscriptions.delete(n);
                        if (!Object.keys(this.subscriptions).some( k => k.endsWith('/' + c))) {
                            unsubs.add(c);   
                        }
                    }
                }
                else {
                    this.subscriptions.delete(n);
                    if (!Object.keys(this.subscriptions).some( k => k.endsWith('/' + c))) {
                        unsubs.add(c);   
                    }
                }
             }
        });

        if (unsubs.length) {
            let aUnsubs = Array.from( unsubs.values());
            this.socket.emit("unsubscribe",aUnsubs);
        }
    }

}

}


