import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/icd.css';
import { useTranslation } from 'react-i18next';


const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="footer mt-auto py-2 nav-background-color footer-bottom">
      <div className="container text-center">
        <p className='privacy-text'> 
        <a className= "privacy-text" href="https://www.cgfns.org/privacy-policy/" target="_blank" rel="noopener noreferrer">
        {t('Privacy_Policy')} </a>
        {' | '} 
        <a className= "privacy-text" href="https://www.icdeval.com/about/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
        {t('Agree_to_t_and_c_2')}</a>
        </p>
        <span className="text-light rights">&copy; {t('Rights_Reserved_Text')}</span>
      </div>
    </footer>
  );
};

export default Footer;
