
import './App.css';
import RoutingConfig from './navigation/RoutingConfig';


function App() {

  return (
    <>
        <RoutingConfig></RoutingConfig>
    </>
  );
}

export default App;
