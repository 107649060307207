import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/cgfns.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3 nav-background-color-cgfns">
      
    </footer>
  );
};

export default Footer;
