import React from "react";

import '../../../assets/css/icd.css';
import Header from '../../../components/icd/icdHeader/icdHeader';
import Footer from '../../../components/icd/icdFooter/icdFooter';
import StatusTracker from '../../../components/cStatusTracker';

const IcdStatusTracker = () => {
    return (
<><Header></Header>
<div className="container-custom-color">
<StatusTracker isICD={true}></StatusTracker>
</div>
<Footer></Footer>
</>
    );
}

export default IcdStatusTracker;