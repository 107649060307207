import React from 'react';
import {  Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SFLink from '../azureLogin/SFLink';

const ProgReportSection = ({ 
  portal,
  showProgReportSection, 
  currentApp, 
  isICDPortal, 
  showRejectionTextDiv, 
  rejectionText, 
  handleClickAccept, 
  handleClickShowReason, 
  handleClickAcceptRejection 
}) => {
    const { t } = useTranslation();
    //debugger;
  return (
    <>
      {currentApp && showProgReportSection && (
        <div
          style={{
            border: '1px solid #00a0a0',
            borderRadius: '15px',            
            padding: '1em',           
            fontSize: '1em'
          }}
        >
          {currentApp.Report_Acceptance__c && (
            <span>
              {t('Please_view_your_report')}
              <a href={currentApp.Program_Report_Link__c} target="_blank" rel="noopener noreferrer">
                <i
                  style={{
                    border: '1px solid blue',
                    borderRadius: '15px',
                    paddingLeft: '5px',
                    paddingRight:'5px',
                    margin: '0.2em'
                  }}
                >
                  {t('here')}
                </i>
              </a>
            </span>
          )}
          {currentApp.RuleCode__c === 'BCEN' && (
            <span>
              {t('Please_view_your_report')}
              <a href={currentApp.Program_Report_Link__c} target="_blank" rel="noopener noreferrer">
                <i
                  style={{
                    border: '1px solid blue',
                    borderRadius: '15px',
                    paddingLeft: '5px',
                    paddingRight:'5px',
                    margin: '0.2em'
                  }}
                >{t('here')}
                </i>
              </a>
            </span>
          )}
          {!currentApp.Report_Acceptance__c && currentApp.External_Status__c !== 'Report Issued to Recipient' && (
            <span>
              {t('Please_view_your_report')}
              <a href={currentApp.Program_Report_Link__c} target="_blank" rel="noopener noreferrer">
                <i
                  style={{
                    border: '1px solid blue',
                    borderRadius: '15px',
                    paddingLeft: '5px',
                    paddingRight:'5px',
                    margin: '0.2em'
                  }}
                >{t('here')}
                </i>
              </a>
              {t('verify_correct')}
            </span>
          )}
          {!isICDPortal && currentApp.RuleCode__c === 'NCNZ1' && (
            <div className="float-right" style={{ marginTop: '-1.2em' }}>
              
              <SFLink path={`/${portal}/s/?action=handleClickAccept&recId=${currentApp.Id}`} >  {t('Accept')}</SFLink>
              <SFLink path={`/${portal}/s/?action=handleClickShowReason&recId=${currentApp.Id}`} >  {t('Reject')}</SFLink>      
          
            
            </div>
          )}
          {isICDPortal && currentApp.External_Status__c === 'Report Issued to Recipient' && (
            <span style={{ fontSize: '1em' }}>
              {t('Please_view_your_report')}
              <a href={currentApp.Program_Report_Link__c} target="_blank" rel="noopener noreferrer">
                <i
                  style={{
                    border: '1px solid blue',
                    borderRadius: '15px',
                    paddingLeft: '5px',
                    paddingRight:'5px',
                    margin: '0.2em'
                  }}
                >
                  {t('here')}
                </i>
              </a>
            </span>
          )}
          <div id="reportRejectionCommentsDkTop">
            {showRejectionTextDiv && (
              <>
                <Form.Control
                  as="textarea"
                  style={{ marginBottom: '2em' }}
                  placeholder="Please enter reason for rejection. A maximum of 255 characters allowed."
                  maxLength="255"
                  value={rejectionText}
                />
                <div className="float-left" style={{ marginTop: '-1.2em' }}>
                  <span>{t('Support_a_submit_255')}</span>
                </div>
                <div className="float-right" style={{ marginTop: '-1.2em' }}>                 
                  <SFLink path={`/${portal}/s/?action=handleClickAcceptRejection&recId=${currentApp.Id}`} >  {t('Submit')}</SFLink>                
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProgReportSection;
