
import { useMsal } from '@azure/msal-react';
import  useAuth from '../azureLogin/useAuth'
import { SocketIoManager } from './socketio';
import { loginRequest } from '../azureLogin/msalConfig';
import {
    useState,
    useEffect,
    useRef
} from 'react';


 const usePushNotifications = (namespace, data) => {
    const { instance, accounts, inProgress } = useMsal();
    
    const socket = SocketIoManager.getSocketIoManager('/notifications');

    const {token } = useAuth();
    const [  notifications, setNotifications ] = useState(data || [])


    useEffect( () => {
        let sub;

        const cb = (event,channel,data) => {
            console.log("Received Notification",data)
            data.Id ||= data.$EventUuid;
            setNotifications(prevState => {
                const updatedNotifications = [...prevState, data];

                // Sort notifications by CreatedDate in descending order
                updatedNotifications.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));

                return updatedNotifications;
              }); 
        }

        const connect = async () => {
            //return instance.acquireTokenSilent(loginRequest,accounts[0]).then( authResult => {
            //    let token = authResult.accessToken;
            //    return socket.current.connect(token);              
            // });
            return token ? socket.connect(token) : Promise.resolve(); 
        }

        const subscribe = async (cb) => {

            if (accounts?.length) {
                let account = accounts[0];
                let contactId = account && account.idTokenClaims ? account?.idTokenClaims['extension_ContactID'] : undefined;  
                socket.subscribe(contactId,"notifyContact",cb);
                return { channel:contactId, event:"notifyContact", cb:cb}
            }
        }


        connect()
        .then( () => {
            return subscribe(cb).then( s => {
                sub = s;
            })
        })
        .catch(ex => {
            console.log("Error subsribing to Notifications:" + ex);
        });

        return () => {
            if (socket && sub) {
                socket.unsubscribe(sub.contactId,sub.event,sub.cb);
            }
        }

    }, [token,accounts,socket]);

    return { notifications, setNotifications };

}

export default usePushNotifications;
