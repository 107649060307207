import { useMsal } from "@azure/msal-react";
import { loginRequest, b2cPolicies, SalesforceResources } from "./msalConfig";
import axios from "axios";

const salesforceResources = new SalesforceResources(); // Create an instance


let salesforceLoginTime;

function makePath(base, rel) {
  return `${base.endsWith('/') ? base.slice(0, -1) : base}/${rel.startsWith('/') ? rel.slice(1) : rel}`;
}

export const useNavigateHandler = (navPath) => {
   // instance  = await useMsal();
  const { instance, accounts } = useMsal();

  const tokenExchangeSSO = async () => {
    //const account = instance.getActiveAccount();
    const account = accounts[0];
    if (!account) {
      throw new Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

      const body = new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
        subject_token: response.accessToken,
        subject_token_type: "urn:ietf:params:oauth:token-type:access_token",
        client_id: salesforceResources.clientId,
        scope: salesforceResources.scope,
        token_handler: salesforceResources.tokenHandler,
      }).toString();

      const tokenResponse = await axios.post(`${salesforceResources.baseUrl}/services/oauth2/token`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept": "application/json",
        },
      });

      const credentials = tokenResponse.data;

      // const dquery = new URLSearchParams({
      //   redirect_uri: `${baseUrl}/applicantPortal/s/portfolio`,
      // });

      // const ddata = await axios.get(makePath(salesforceResources.baseUrl, `/Sites/applicantPortal/frontdoor?${dquery.toString()}`), {
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-API-Key": credentials.access_token,
      //     "Authorization": `Bearer ${response.accessToken}`,
      //   },
      // });
      const redirect_uri2= `${navPath}`;
      const responsenew = await fetch(`${salesforceResources.navigateUrl}?redirect_uri=${encodeURIComponent(redirect_uri2)}`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${response.accessToken}` ,
          'X-Api-Key': credentials.access_token,
        }
      });


      let data = await responsenew.json();
      console.log('responsenew',data);
      return data.frontdoor_uri;

    } catch (error) {
      console.log("Token exchange error:", error);
      throw error;
    }
  };

  const samlSSO = (path) => {
    const url = new URL(b2cPolicies.authorities.saml.authority);
    url.searchParams.set("RelayState", path);
    return url.href;
  };

  const navigateHandler = async (path, newWindow = false) => {
    try {
      let url;

      if (salesforceLoginTime) {
        url = makePath(salesforceResources.baseUrl, path);
      } else {
        switch (b2cPolicies.ssoMethod) {
          case "saml":
            url = samlSSO(path);
            break;
          case "token_exchange":
            url = await tokenExchangeSSO();
            break;
          default:
            throw new Error("Invalid ssoMethod");
        }
      }

      console.log("Redirecting to:", url);

      if (newWindow) {
        window.open(url, "_blank");
      } else {
        window.location.assign(url);
      }
    } catch (error) {
      console.log("Navigation error:", error);
    }
  };

  return navigateHandler;
};
