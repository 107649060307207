import { LogLevel } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signUpSignIn: `${process.env.REACT_APP_SIGNUP_SIGNIN}`,
    forgotPassword: `${process.env.REACT_APP_FORGOT_PASSWORD}` ,
    editProfile: `${process.env.REACT_APP_EDIT_PROFILE}`,
  },
  authorities: {
    signUpSignIn: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_SIGNUP_SIGNIN}`,
    },
    forgotPassword: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_FORGOT_PASSWORD}`,
    },
    editProfile: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_EDIT_PROFILE}`,
    },
    saml: {
      authority: `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/${process.env.REACT_APP_SIGNUP_SIGNIN_SAML}`
    }
  },
  authorityDomain: `${process.env.REACT_APP_AUTHORITY_DOMAIN}`,
  ssoMethod: "token_exchange"
};

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri:window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  interactionType: InteractionType.Redirect,
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error: console.error(message); break;
          case LogLevel.Info: console.info(message); break;
          case LogLevel.Verbose: console.debug(message); break;
          case LogLevel.Warning: console.warn(message); break;
        }
      },
    },
    allowNativeBroker: false,
  },
};

// export const loginRequest = {
//   scopes: [`${process.env.REACT_APP_SCOPE_URL}/integrationserver/contact`],
// };


class ApiContactResources {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.queries = new ApiContactQueries(this);
    this.scopes = {
      me: [`${process.env.REACT_APP_SCOPE_URL}`],
    };
  }
}

class ApiContactQueries {
  constructor(rsrc) {
    this.rsrc = rsrc;
  }

  get contact() {
    return `${this.rsrc.endpoint}/Contact/me?fields=Id,Name,Email,box_profile_pic_url__c,Phone,Applicant_ID__c,AvailableApplications.Program_Name__c,` +
      "AvailableApplications.order_Payment_Date__c,AvailableApplications.application_expiration_date__c," +
      "AvailableApplications.Internal_Status__c,AvailableApplications.Program_Report_Link__c,AvailableApplications.order_Payment_Date__c," +
      "AvailableApplications.application_expiration_date__c";
  }

  get photo() {
    return `${this.rsrc.endpoint}/Contact/me/photo`;
  }
}

export const protectedResources = {
  apiContact: new ApiContactResources(`${process.env.REACT_APP_SCOPE_URL}`),
};



// Salesforce Resources
export  class SalesforceResources {
  constructor() {

    this.baseUrl = `${process.env.REACT_APP_SALESFORCE_BASE_URL}`;
    this.clientId = `${process.env.REACT_APP_SALESFORCE_CLIENT_ID}`;
    
    this.scope = "full";
    this.tokenHandler = "B2CTokenExchangeHandler";
    this.PORTALS = ['applicantPortal', 'icd'];
    this._portal = this._getPortal();

    this.navigateUrl = `${process.env.REACT_APP_WEBSITE_NAMES_AZURE}/Sites/${this._portal}/frontdoor`;
    
  }

  _getPortal() {
    let p = window.location.pathname.split('/').filter(v => v);
    if (p.some(v => v ==='icd')) {
      return this.PORTALS[1];
    } 
    else  {
      return this.PORTALS[0];
    }
    return this.PORTALS[0];
  }

  get portal() {
    return this._portal;
  }

  get portalUrl() {
    return `${this.baseUrl}/${this.portal}`;
  }

  get singleAccessUrl() {
    return `${this.portalUrl}/services/oauth2/singleaccess`;
  }

  get portalTokenEndpoint() {
    return `${this.portalUrl}/services/oauth2/token`;
  }

  get portalLogoutEndpoint() {
    return `${this.portalUrl}/services/auth/idp/oidc/logout`;
  }
}

export const salesforceResources = new SalesforceResources();

export const loginRequest = {
  scopes: [...protectedResources.apiContact.scopes.me],
  extraQueryParameters: {portal:salesforceResources.portal}
};