import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/icd.css';
import { useTranslation } from 'react-i18next';
import SFLink from '../azureLogin/SFLink';

const ServiceSelectorTrackerICD = ({ currentApp, isICDPortal, acproQuestionnaireFilled }) => {
    const { t } = useTranslation();
    const [tabToHighlight, setTabToHighlight] = useState('1');
   //debugger;
    useEffect(() => {
        if (currentApp) {
            if (isICDPortal) {
                if (!currentApp.isProfileDataFilled__c && !currentApp.isICDAppReadyToApply__c && !currentApp.isICDRecipientFilled__c) {
                    setTabToHighlight('2');
                } else if (currentApp.isProfileDataFilled__c && currentApp.RuleCode__c === 'ACPRO' && !acproQuestionnaireFilled) {
                    setTabToHighlight('3');
                } else if (currentApp.isProfileDataFilled__c && !currentApp.isICDAppReadyToApply__c && !currentApp.isICDRecipientFilled__c && currentApp.RuleCode__c !== 'ACPRO') {
                    setTabToHighlight('3');
                } else if (currentApp.isProfileDataFilled__c && currentApp.isICDAppReadyToApply__c && currentApp.isICDRecipientFilled__c && currentApp.RuleCode__c !== 'ACPRO') {
                    setTabToHighlight('4');
                } else if (currentApp.isProfileDataFilled__c && currentApp.RuleCode__c === 'ACPRO' && acproQuestionnaireFilled) {
                    setTabToHighlight('4');
                } else {
                    setTabToHighlight('1');
                }
            } else {
                if (!currentApp.isProfileDataFilled__c && !currentApp.Chargent_Order__c) {
                    setTabToHighlight('2');
                } else if (currentApp.isProfileDataFilled__c && !currentApp.Chargent_Order__c) {
                    setTabToHighlight('3');
                } else {
                    setTabToHighlight('1');
                }
            }
        }
    }, [currentApp, isICDPortal, acproQuestionnaireFilled]);

    const navigateTo = (pageName) => {
        // Implement navigation logic here, e.g., using react-router or window.location
        console.log(`Navigating to ${pageName}`);
    };

    return (
        <div className="stepTabBox">
            <ul className="stepTab">
                <li className={`stepfirst ${tabToHighlight === '1' ? 'activeTab' : (tabToHighlight>1?' completed':'')}`} >

                    {tabToHighlight === '1' ? `${t('S1_service_selector')}` : <button className='anchor-button' href="#" >

                        {t('S1_service_selector')}</button>}

                </li>

                <li className={` ${tabToHighlight === '2' ? 'activeTab' :  (tabToHighlight>2?' completed':'')}`}  >

                    {tabToHighlight === '2' ? <SFLink path="/icd/s/profile-v1" variant="a" >${t('S2_service_selector')}</SFLink> :<SFLink path="/icd/s/profile-v1" variant="a" >{t('S2_service_selector')} 
                    </SFLink> }

                </li>

                <li className={` ${tabToHighlight === '3' ? 'activeTab' :  (tabToHighlight>3?' completed':'')}`}  >

                    <button className='anchor-button' href="#" onClick={() => navigateTo(isICDPortal ? 'recipient-input' : 'cgnc-questionnaire')}>

                        {isICDPortal ? t('S3_service_selector') : t('CGNC_Questionnaire')}

                    </button>

                </li>

                <li className={`steplast ${tabToHighlight === '4' ? 'activeTab' : ''}`} >

                    <button className='anchor-button' href="#" tabIndex="-1">

                        {t('S4_service_selector')}

                    </button>

                </li>

            </ul>
        </div>
    );
};

export default ServiceSelectorTrackerICD;
