

import { createContext, useContext, useState, useEffect, Response } from "react";
import { jwtDecode } from "jwt-decode";



const ImplicitAuthContext = createContext(null);


//The hook used by components to access the Access Token or fetch data from an API that requires the token.
export function useImplicitAuth() {
  return useContext(ImplicitAuthContext);
}

export default function ImplicitAuthProvider({children}) {

    const [accessToken, setAccessToken] = useState(null);


  function extractToken() {

    let access_token  = (new URLSearchParams(window.location.href.slice(window.location.href.indexOf('#') + 1))).get("access_token");
    if (access_token) {
      console.log("access_token", access_token);
      if (access_token != accessToken) {
      //Generally it would be wise to some validation of the access token here.  Checking that its not expired, that the audience is correct,
      //that the scope is appropriate, etc. Checking these things here would allow us to perhaps generate more helpful error messages,
      //avoid making server API calls that are sure to fail, etc.  However, we can never rely solely on verifying credentials in
      //a public brower-based client application.  In this example, the access token will be fully validated by the Integration Server
      //in its secure runtime environment and it will not provide private data if the token is not valid.  This means information about the
      //order and the applicant will only be returned if the token is valid.  An invalid token will also prevent the Paypal client library from 
      //rendering the Payment Form, because that client needs a Paypal Client Token which can only be obtained from the Integration Server 
      //using a valid Access Token.
        setAccessToken(access_token );
      }
      return null;
    }
    else if (!accessToken) {
      return implicitAuthRedirect() ? 
      (<div style={{backgroundColor:"white",padding:"30px"}}>Authenticating....</div>)
      :
      (<div style={{backgroundColor:"white",padding:"30px", border:"2px solid red"}}>Error: Invalid or Missing Access Token or TOTP token</div>)
    }
  }


   function isAuthenticated() {
      return accessToken != null;
   }

   function jwt() {
      return accessToken ? jwtDecode(accessToken) : undefined;
   }

    async function fetchData (path, cfg)  {
    try {
        //if a relative path is provided, assume it is an API on the Integration Server
        //and generate the corresponding absolute path
        if (!path.startsWith("http"))
            path = `${process.env.REACT_APP_WEBSITE_NAMES}${path[0] == "/" ? "" : "/"}${path}`;

        //create missing fetch option objects that may be missing
        cfg ||= {};
        cfg.headers ||= {};

        //if this request is to the Integration Server, add our Access Token as the Bearer Token
        if (path.startsWith(process.env.REACT_APP_WEBSITE_NAMES)) {
          cfg.headers.Authorization = `Bearer ${accessToken}`;
        }

        //assume GET method if not specified
        cfg.method ||= "GET";

        //assume JSON for body if not specified
        if (cfg.body) {
          cfg.headers['Content-Type'] ||= "application/json";
        }

        return  fetch( path, cfg );

     } catch (error) {
        console.log("Failed to fetch data:", error);
        throw error; 
      }
    }

  const contextValue = {
    accessToken,
    fetchData,
    isAuthenticated,
    jwt
  };


  return ( extractToken() ||
    <ImplicitAuthContext.Provider value={contextValue}>
      {children}
    </ImplicitAuthContext.Provider>
  
    
  );
}

//for best user experience, this should be called as early as possible during Page load
//ideally before the first UI render occurs. If the redirect happens prior to the render,
//in most browsers the browser view will not change and the user will not notice the redirect
//to the Authorize endpoint and its redirect right back.  When the redirect occurs after the render,
//the view will appear to flicker or flash as the app starts.
//It can also significantly reduce the time to load the application by redirecting before wasting
//time on initializing the app.
export function implicitAuthRedirect() {
  let token_hint = (new URLSearchParams(window.location.search)).get("token");
  if (token_hint) {
      let params = new URLSearchParams();
      params.set('p',process.env.REACT_APP_TOTP_SIGNIN);
      params.set('client_id',process.env.REACT_APP_PAYMENTS_CLIENT_ID);
      params.set('scope',process.env.REACT_APP_ORDER_SCOPE);
      params.set("response_type","token");
      params.set("id_token_hint", token_hint);
      params.set("redirect_uri",  [window.location.origin,window.location.pathname].join("") );
      window.location.href = `${process.env.REACT_APP_SIGNUP_SIGNIN_AUTHORITY}/oauth2/v2.0/authorize?${params.toString()}`;
      return true;
  }
  return false;
}


