import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useNavigateHandler } from './navigateHandler';
import { loginRequest } from './msalConfig';

const SFLink = ({ path, children, variant, ...rest }) => {
  console.log(path, "path");
  const navigate = useNavigate();
  const navigateHandler = useNavigateHandler(path);
  const { instance, accounts } = useMsal();

  const handleClick = async (e) => {
    e.preventDefault();

    try {
      if (!instance.getActiveAccount() && accounts.length === 0) {
        throw new Error("No active account found and no accounts available for silent acquisition.");
      }

      // Check if user is signed in and set active account
      if (!instance.getActiveAccount()) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
          });
          instance.setActiveAccount(response.account);
        } catch (silentError) {
          console.log("Silent token acquisition failed. Fallback to loginPopup if needed.", silentError);
          await instance.loginRedirect(loginRequest);
        }
      }

      const url = await navigateHandler(path);
      if (url) {
        window.location.href = url; // Redirect if URL is obtained
      } else {
        navigate(path); // Fallback to React Router navigation
      }
    } catch (error) {
      console.log('Navigation error:', error);
    }
  };

  return React.createElement(variant || 'a', { href: path, onClick: handleClick, ...rest }, children);
};

export default SFLink;
