import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/cgfns.css';
import { useTranslation } from 'react-i18next';
import SFLink from '../azureLogin/SFLink';

const ServiceSelectorTrackerCGFNS = ({ currentApp, isICDPortal }) => {
    const { t } = useTranslation();
    //debugger;
    const [tabToHighlight, setTabToHighlight] = useState('1');
   // debugger;
    useEffect(() => {
        if (currentApp) {
            if (isICDPortal) {
                if (!currentApp.isProfileDataFilled__c && !currentApp.isICDAppReadyToApply__c && !currentApp.isICDRecipientFilled__c) {
                    setTabToHighlight('2');
                } else if (currentApp.isProfileDataFilled__c && !currentApp.isICDAppReadyToApply__c && !currentApp.isICDRecipientFilled__c) {
                    setTabToHighlight('3');
                } else if (currentApp.isProfileDataFilled__c && currentApp.isICDAppReadyToApply__c && currentApp.isICDRecipientFilled__c) {
                    setTabToHighlight('4');
                } else {
                    setTabToHighlight('1');
                }
            } else {
                if (!currentApp.isProfileDataFilled__c && !currentApp.Chargent_Order__c) {
                    setTabToHighlight('2');
                } else if (currentApp.isProfileDataFilled__c && !currentApp.Questionnaire_Obtained__c) {
                    setTabToHighlight('3');
                } else if (currentApp.isProfileDataFilled__c && currentApp.Questionnaire_Obtained__c) {
                    setTabToHighlight('4');
                } else {
                    setTabToHighlight('1');
                }
            }
        }
    }, [currentApp, isICDPortal]);

    const navigateTo = (pageName) => {
        // Implement navigation logic here, e.g., using react-router or window.location
        console.log(`Navigating to ${pageName}`);
    };

    return (
       
        <div className="stepTabBox">
         { currentApp && currentApp.RuleCode__c ==='CGNC-ICN' ? ( 
            <ul className="stepTab-cgfns">
                <li className={`stepfirst  ${tabToHighlight === '1' ? 'activeTab' : (tabToHighlight>1?' completed':'')}`} role="presentation">
                    {tabToHighlight === '1' ? `${t('S1_service_selector')}` : <button className='anchor-button' href="#" >{t('S1_service_selector')}</button>}
                </li>
                <li className={` ${tabToHighlight === '2' ? 'activeTab' : (tabToHighlight>2?' completed':'')}`} role="presentation" >
                    {tabToHighlight === '2' ? <SFLink path="/applicantPortal/s/profile-v1" variant="a" >${t('S2_service_selector')}</SFLink> : <SFLink path="/applicantPortal/s/profile-v1" variant="a" >{t('S2_service_selector')} 
                    </SFLink> }                       
                   
                </li>
                <li className={` ${tabToHighlight === '3' ? 'activeTab' : (tabToHighlight>3?' completed':'')}`} role="presentation" >
                    <button className='anchor-button' href="#" onClick={() => navigateTo(isICDPortal ? 'recipient-input' : 'cgnc-questionnaire')}>
                        {isICDPortal ? `${t('S3_service_selector')}` : `${t('CGNC_Questionnaire')}`}
                    </button>
                </li>
                <li className={`steplast ${tabToHighlight === '4' ? 'activeTab' : ''}`} role="presentation">
                    <button className='anchor-button' href="#" tabIndex="-1">
                        {t('S4_service_selector')}
                    </button>
                </li>
            </ul>
        ) : (
            <ul className="stepTab-cgfns">
                <li className={`stepfirst  ${tabToHighlight === '1' ? 'activeTab' : (tabToHighlight>1?' completed':'')}`} role="presentation">
                    {tabToHighlight === '1' ? `${t('S1_service_selector')}` : <button className='anchor-button' href="#" >{t('S1_service_selector')}</button>}
                </li>
                <li className={` ${tabToHighlight === '2' ? 'activeTab' : (tabToHighlight>2?' completed':'')}`} role="presentation">
                    {tabToHighlight === '2' ? <SFLink path="/applicantPortal/s/profile-v1" variant="a" >${t('S2_service_selector')}</SFLink> : <SFLink path="/applicantPortal/s/profile-v1" variant="a" >
                        {t('S2_service_selector')}
                    </SFLink>}
                </li>
                <li className={`steplast ${tabToHighlight === '3' ? 'activeTab' : ''}`} role="presentation">
                    <button className='anchor-button' href="#" tabIndex="-1">
                        {t('S3_apply_pay')}
                    </button>
                </li>
            </ul>
        ) }
        </div>


    );
};

export default ServiceSelectorTrackerCGFNS;
