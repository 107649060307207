import { PaymentForm } from "../../components/Payments/PaymentForm";
import { useState, useEffect } from "react";
import { useImplicitAuth } from "../../azureLogin/useImplicitAuth";
import ImplicitAuthContext from "../../azureLogin/useImplicitAuth";



const PaymentsHome = () => {
  const { fetchData, jwt } = useImplicitAuth();
  const [message, setMessage] = useState("Loading...");
  const [order, setOrder] = useState({});



  useEffect(() => {
    (async () => {
      let orderId = jwt().extension_OrderId;
      const response = await fetchData(`/federated/Application/${orderId}`);
      console.log("federated", response);
      if (response.ok) {
        let order = await response.json();
        order.lineBalance = order.lineBalance
        setOrder(order);
        console.log("Order Record", order);
      } else {
        let err = await response.json();
        setMessage(`${err && err.name ? err.name : "Unknown Error"}: ${err && err.message ? err.message : "an unknown error occurred"}`);
      }
    })();
  }, []);
  

  return (
    <>
      {order ? (
        <PaymentForm order={order} />
      ) : (
        <h4 style={{ backgroundColor: "white", padding: "30px" }}>{message}</h4>
      )}
    </>
  );
};




const PaymentsSite = () => {

  return (
    <>

      <ImplicitAuthContext>
        <PaymentsHome />
      </ImplicitAuthContext>
    
    </>
  );
};

export default PaymentsSite;

