import React, { useEffect, useState, useRef  } from 'react';
import axios from 'axios';
import { FaBell,FaTimes  } from 'react-icons/fa';
import { FaBolt  } from 'react-icons/fa6';
import '../assets/css/Notifications.css'; // Import your CSS file for styling
import useAuth from '../azureLogin/useAuth';
import { AuthenticatedTemplate } from '@azure/msal-react';
import usePushNotifications from './usePushNotifications';

const Notifications = () => {
  const { token } = useAuth();
  const { notifications, setNotifications } = usePushNotifications('/notifications'); // initialize with namespace
  const [unreadCount, setUnreadCount] = useState(0);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);


  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);

  // Fetch initial notifications on startup
  useEffect(() => {
    const fetchNotifications = async () => {
      if (!token) {
        console.log("Token is not available");
        return;
      }

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_WEBSITE_NAMES_AZURE}/Contact/me/Notification`,
        headers: {
          'accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };

      try {
        const response = await axios(config);
        const notificationsData = response.data;
         // Sort notifications by CreatedDate in descending order
         notificationsData.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate));

        setNotifications(notificationsData);

        // Calculate the number of unseen or unread notifications
        const unreadOrUnseen = notificationsData.filter(notification => 
          !notification.Seen__c || !notification.Read__c
        );
        setUnreadCount(unreadOrUnseen.length);
      } catch (error) {
        console.log('Error fetching data', error);
      }
    };

    fetchNotifications();
  }, [token, setNotifications]);

  // Update unread count when notifications change
  useEffect(() => {
    const unreadOrUnseen = notifications.filter(notification => 
      !notification.Seen__c || !notification.Read__c
    );
    setUnreadCount(unreadOrUnseen.length);
  }, [notifications]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(dateString).toLocaleString('en-US', options);
  };


  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  return (
    <AuthenticatedTemplate>
      <div className="notifications-wrapper">
        <div className="bell-icon-container" onClick={toggleDropdown}>
          <FaBell className="notifications-icon" />
          {unreadCount > 0 && (
            <span className="notification-badge">{unreadCount}</span>
          )}
        </div>
        {dropdownVisible && (
          <div className="notifications-dropdown" ref={dropdownRef}>
            <div className="notifications-header">
              <span>Notifications</span>
              <FaTimes className="close-button" onClick={toggleDropdown} />
            </div>
            {notifications.map(notification => (
              <div key={notification.Id} className="notification-item">
                {notification.Image__c ? (
                  <img src={notification.Image__c} alt="Notification Icon" className="notification-image" />
                ) : (
                  <FaBolt style={{ color: '#1b96ff', fill: '#1b96ff', width: '24px', height: '24px', marginRight: '10px' }} className="notification-image" />
                )}
                <div className="notification-content">
                  <div className="notification-content-header">{notification.MessageTitle__c}</div>
                  <p>{notification.MessageBody__c}</p>
                  <p className="notification-date-time">{formatDate(notification.CreatedDate)}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </AuthenticatedTemplate>
  );
};

export default Notifications;
