import { useState } from "react";

const OrderView = ({ order }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // Track processing state

  // Validate order data before allowing payment
  const validateOrder = () => {
    if (!order || parseFloat(order?.lineBalance) <= 0) {
      return "Payment cannot be processed. No outstanding balance.";
    }

    if (!order?.Contact?.FirstName || !order?.Contact?.LastName) {
      return "Applicant name is missing. Please ensure all order details are correct.";
    }

    if (!order?.orderNumber) {
      return "Order number is missing. Cannot proceed with payment.";
    }

    if (order?.isExpired) {
      return "This order has expired and cannot be paid.";
    }

    return null; // No validation errors
  };

  // Validate payment before processing
  const handlePaymentClick = (paymentMethod) => {
    const validationError = validateOrder();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    // Reset error and start processing payment
    setErrorMessage("");
    setIsProcessing(true);

    // Handle different payment methods
    if (paymentMethod === "PayPal") {
      console.log("PayPal payment initiated");
      // Simulate processing time
      setTimeout(() => setIsProcessing(false), 2000); // Simulate payment processing
    } else if (paymentMethod === "PayLater") {
      console.log("Pay Later payment initiated");
      setTimeout(() => setIsProcessing(false), 2000);
    }
  };

  return (order ? (
    <div className="container mt-4">
      <div className="row mb-2">
        <div className="col-12 d-flex">
          <div className="fw-bold d-flex justify-content-between" style={{ minWidth: "130px" }}>
            <span>Applicant Name</span><span>:</span>
          </div>
          <div className="ms-3">{order ? [order?.Contact?.FirstName ?? "",order?.Contact?.LastName ?? ""].join(" ") : ""}</div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 d-flex">
          <div className="fw-bold d-flex justify-content-between" style={{ minWidth: "130px" }}>
            <span>Program</span><span>:</span>
          </div>
          <div className="ms-3">{`${order?.orderType ?? ""}`} {order?.orderCode ? ` (${order?.orderCode})` : ""}</div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 d-flex">
          <div className="fw-bold d-flex justify-content-between" style={{ minWidth: "130px" }}>
            <span>Order Number</span><span>:</span>
          </div>
          <div className="ms-3">{order?.orderNumber ?? ""}</div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 d-flex">
          <div className="fw-bold d-flex justify-content-between" style={{ minWidth: "130px" }}>
            <span>Amount Due</span><span>:</span>
          </div>
          <div className="ms-3">${order?.lineCost ? parseFloat(order.lineCost).toFixed(2) : "0.00"}</div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12 d-flex">
          
        </div>
      </div>

      {/* Error message display */}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      {/* Disable buttons when processing */}
      {isProcessing && <div className="alert alert-info">Processing payment, please wait...</div>}
      
    </div>
  ) : (
    <div>Loading Order...</div>
  ));
};

export default OrderView;
