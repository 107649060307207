


import { useMsal } from '@azure/msal-react';
import { loginRequest } from './msalConfig';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [token, setToken] = useState(null);
  const [error, setError] = useState('');
  const [interactionInProgress, setInteractionInProgress] = useState(false);
  const loginAttempted = useRef(false);
  const popupClosed = useRef(false);

  useEffect(() => {
    const handleLogin = async () => {
      if (interactionInProgress || inProgress !== 'none' || loginAttempted.current || popupClosed.current) return; // Prevent multiple interactions

      setInteractionInProgress(true);
      try {
        const account = accounts[0];
        if (account) {
          // Attempt silent token acquisition
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
          });
          setToken(response.accessToken);
        } else {
          // If no account, perform interactive login
          loginAttempted.current = true;
          const loginResponse = await instance.loginRedirect(loginRequest).catch(error => {
            if (error.errorCode === 'user_cancelled') {
              popupClosed.current = true;
            }
            throw error;
          });
          setToken(loginResponse.accessToken);
          loginAttempted.current = false;
          popupClosed.current = false; // Reset the flag after successful login
        }
      } catch (error) {
        console.log("Failed to acquire token silently:", error);
        setError("Authentication failed. Please try again.");
        loginAttempted.current = false; // Reset the login attempt flag
      } finally {
        setInteractionInProgress(false); // Reset interaction flag
      }
    };

    handleLogin();
  }, [instance, accounts, interactionInProgress, inProgress]);

  const fetchData = async () => {
    try {
      const account = accounts[0];
      if (!account) {
        console.log("No account found");
        return;
      }

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      const apiResponse = await axios.get(`${process.env.REACT_APP_WEBSITE_NAMES_AZURE}/Contact/me/photo`, {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
        },
        responseType: 'blob' // Request as Blob
      });

      const imageUrl = URL.createObjectURL(apiResponse.data); // Use response.data to get the Blob
      return { token: response.accessToken, imageUrl };
    } catch (error) {
      console.log("Failed to fetch data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };

  const logout = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin,
      });
      setToken(null);
      console.log("User logged out and session cleared");
    } catch (error) {
      console.log("Error during logout: ", error);
      setError(error.message || "Logout failed");
    }
  };

  return { token, fetchData, error, logout };
};

export default useAuth;
